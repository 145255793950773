import React, {useState} from 'react';
import '../style.css'

// Given some object of contents in the form {tabName:tabname, content:content} create tabs with those respective names and content
const Tabs = ({tabContents}) => {
    
    //when tab is clicked change toggleState to the unique number given to represent that tab (aka the index that tab)
    const [toggleState, setToggleState] = useState(0);
    const toggleTab = (num) => {
        setToggleState(num);
    };

    const handleKeyPressToggleState = (e,index) => {
        if(e.key === 'Enter')
            toggleTab(index)
    }

    //The content div classes will change based on the above state changing and show the content associated with the current state number
    return(
        <div style={{display:'flex', flexDirection:'column', marginTop:'1%'}} >
            <div className='tab-container'>
            {tabContents.map((item, index) => <div role="button" tabIndex={0} key={item.tabName} onKeyDown={(e)=>handleKeyPressToggleState(e,index)} onClick={()=>toggleTab(index)} className={toggleState === index ? 'tabs active-tab' : 'tabs'}>{item.tabName}</div>)}
            </div>
            <div>
                {tabContents.map((item, index) => 
                    <div key={item.tabName} className={toggleState === index ? 'content-tabs active-content' : 'content-tabs'} >
                        {item.content}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Tabs
